import React from 'react'
import ContentContainer from '../Layout/ContentContainer'
import FadeInUpAnimation from '../FadeInUpAnimation/FadeInUpAnimation'
import TextWithDots from '../TextWithDots/TextWithDots'

const PrivacyPolicy = () => (
  <ContentContainer>
    <div className='mb-20'>
      <FadeInUpAnimation>
        <h1 className='my-10'>
          <TextWithDots>Privacy Policy</TextWithDots>
        </h1>
        <p>
          This Privacy Policy describes how we collect, use, and disclose
          information from visitors to our website. By using our website, you
          agree to the collection and use of information in accordance with this
          Privacy Policy.
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Administrator ‘s Data</h2>
        <p>
          We hereby inform that the Administrator of your personal data is
          TheGoodCode Sp. z o.o. with its registered office in Gdańsk (post
          code: 80-386) at: ul. Lęborska 3B, entered in the Entrepreneur
          Register of the National Court Register [KRS] conducted by Regional
          Court “Gdańsk – Północ” in Gdańsk VII Department under the KRS number
          0000902081, tax ID [NIP]: 6040223726, business statistical number
          [REGON] 388990223, hereinafter referred to as: “The Company”;
        </p>
        <p className='mt-5'>
          You may contact The Company regarding any case related to the
          processing of your personal data on the following address
          legal@thegoodcode.io.
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Data Protection Officer</h2>
        <p>
          The Company has appointed a Data Protection Officer, currently the
          position is occupied by Mr Aleksander Twardowski Attorney at law who
          shall assist you in any matter related to the processing of your
          personal data. You may contact the Data Protection Officer via the
          following address: aleksander.twardowski@adwokatura.pl.
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>
          Basis and purposes of processing personal data
        </h2>
        <p>
          In order to properly provide services or enter into agreements in
          accordance with the Company’s scope of business, The Company shall
          process your personal data for a variety of purposes with accordance
          with the provisions of the Polish and European law. You shall find the
          specified purposes as well as the legal basis for the processing
          indicated below:
        </p>
        <p>
          To provide services or enter into agreements The Company processes the
          following types of data:
          <ul className='bullet'>
            <li>Name and last name;</li>
            <li>Place of residence;</li>
            <li>Telephone number;</li>
            <li>Email address;</li>
            <li>PESEL (Polish identification) number;</li>
            <li>Number and series of Personal identification document;</li>
          </ul>
          The legal basis of the processing is specified in article 6 section 1
          letter b of GDPR which entitles the Administrator to process personal
          data in order to properly execute the agreement or undertake any
          required steps to enter into an agreement.
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Cookies</h2>
        <p>
          <ol>
            <li>
              Similar to many entities functioning on the market, The Company
              exploits “Cookies” on its website. A cookie is a small computer
              file or piece of information that may be stored in your computer's
              hard drive when you visit our websites. We may use cookies to
              improve our website’s functionality and in some cases, to provide
              visitors with a customized online experience. Cookies may be read
              by our system, or by the systems of other entities which provide
              services for The Company.
            </li>
            <li>
              Cookies provide many useful features for the user, which are
              listed below (in case of the need for any further information
              regarding cookies, please contact The Company).
              <ul className='bullet'>
                <li>
                  Ensuring security – cookies are used to in order to
                  authenticate users and prevent unauthorized access to
                  customer’s panel therefore protecting personal data from being
                  accessed by unauthorized parties.
                </li>
                <li>
                  Impact on operational processes and website performance –
                  cookies are used to ensure the proper functionality of the
                  website and its smooth operation which are possible inter alia
                  by memorization of settings between visits on the website.
                  Cookies enable easier navigation on the website and subpages.
                </li>
                <li>
                  Session state – Cookie files frequently store the information
                  regarding the user’s conduct on the website. Due to the
                  aforementioned – the user may switch to other websites without
                  the necessity to repeatedly log in, which ensures comfort
                  during visits on the website.
                </li>
                <li>
                  Creating statistical data – cookies are used to analyze the
                  conduct of users during the visits on our website (how many
                  users open our website, how much time they spend on the
                  website, which content is of interest to the users etc.). Due
                  to the utilization of cookies, the website may be constantly
                  improved and adjusted to the preferences of the users. In
                  order to track the activities and creating statistics we use
                  Google’s tools such as Google Analytics; apart from reporting
                  the statistics of website’s traffic, Google Analytics by
                  working with cookies may optimize users internet search by
                  exposing user to more accurate content in Google services and
                  the entire web.
                </li>
              </ul>
              <li>
                We would like to inform that most of the cookies used by The
                Company’s website are anonymized and we are unable to identify
                our users based solely on cookies.
              </li>
              <li>
                By default your browser allows the use of cookies on your
                device, therefore we kindly ask you to allow the use of cookies
                during your first visit to our website. If you do not wish to
                use cookies while browsing the website, you can change the
                settings in the web browser - completely block the automatic
                support for cookies or request notification whenever cookies are
                saved on the device.
              </li>
              <li>
                While respecting the autonomy of all persons using the website,
                we feel obliged to warn that the exclusion or limitation of
                cookies may cause quite serious difficulties in using the
                website, e.g. longer loading time of the website, limitations in
                using the functionalities.
              </li>
            </li>
          </ol>
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>The right to withdraw consent </h2>
        <p>
          <ol>
            <li>
              If the processing of your personal data is based on consent, at
              any moment you are entitled to withdraw consent – at your
              discretion.
            </li>
            <li>
              Should you choose to withdraw consent you may follow one of the
              following steps:
              <ul className='bullet'>
                <li>
                  Send an email directly to the Administrator – The Company at
                  legal@thegoodcode.io.
                </li>
                <li>
                  Send an email to the Data Protection Officer at
                  aleksander.twardowski@adwokatura.pl.
                </li>
                <li>
                  Click the link found at the bottom of any email received from
                  The Company.
                </li>
              </ul>
            </li>
            <li>
              If the processing of your personal data was based on consent, its
              withdrawal does not deem the processing of personal data, until
              the moment of withdrawal, illegal.
            </li>
          </ol>
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>The requirement to provide personal data </h2>
        <p>
          <ol>
            <li>
              Providing your personal data is voluntary, however should you
              refuse to provide it, the execution of the contract or providing
              the services by our Company may not be possible.
            </li>
            <li>
              Providing your personal data is voluntary, however should you
              refuse to provide it, the execution of the contract or providing
              the services by our Company may not be possible.
            </li>
            <li>
              In order to receive a properly issued invoice form The Company, it
              is necessary to provide all data required by Polish tax law, which
              are: name, last name, place of residence or the headquarters of
              company, tax identification number. With no access to the
              aforementioned data, we are unable to issue and invoice.
            </li>
            <li>
              In order to contact you via telephone in matters regarding the
              execution of the agreement, it is necessary to provide you
              telephone number. With no access to the aforementioned data, we
              are unable to contact you via the telephone.
            </li>
          </ol>
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Automated decisions and profiling data</h2>
        <p>
          We would like to inform you that the Company does not make decisions
          in an automated manner and your data shall not be profiled.
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Personal data receivers </h2>
        <p>
          <ol>
            <li>
              The Company uses the services of external service providers which
              may result in necessity of transferring your personal data. Due to
              the aforementioned fact, we may transfer your personal data to
              other entities in order to provide services or execute the
              contract. We assure you that the entities that shall be processing
              your personal data implement data processing procedures non less
              stringent than the Company’s.
            </li>
            <li>
              Apart from the aforementioned transfers, we may be obligated to
              transfer your personal data to other public or private entities
              (i.e. based on a court order or administrative decision).
              Therefore, The Company can not predict a fixed catalogue of
              entities that may receive your personal data, yet The Company
              ensures that any request for access to personal data is analyzed
              thoroughly by our legal team in order to avoid the situation of
              transferring your data to unauthorized parties.
            </li>
          </ol>
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Transfer of data outside of the EU </h2>
        <p>
          We would like to inform you that we are not transferring your personal
          data outside of the European Economic Area.
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Period of personal data processing</h2>
        <p>
          <ol>
            <li>
              In accordance with the law, we do not process your personal data
              indefinitely. Your personal data is processed for the period of
              time necessary to fulfill the purpose for which they were
              disclosed. After that period expires, your personal data shall be
              irreversibly deleted or/and destroyed.
            </li>
            <li>
              In the event when The Company does not require to perform any
              operations on your personal data apart from storing it until they
              are destroyed or deleted, The Company additionally protects your
              data from disclosure by pseudonymization. Pseudonymization is a
              process that ciphers your data or database, in a way that they may
              not be disclosed without entering the security passwords, which
              prohibits any unauthorized person from accessing your data even in
              the event of its theft or misplacement.
            </li>
            <li>
              We process your personal data for the following periods of time:
              <ul className='bullet'>
                <li>
                  For 5 years – regarding the data such as: name, last name,
                  address, PESEL number, Personal identification document
                  number, telephone number, email address – processed in order
                  to enter into contract or employment agreement and to comply
                  with the accounting obligations;
                </li>
                <li>
                  For 3 years - regarding the data such as: name, last name,
                  address, PESEL number, Personal identification document
                  number, telephone number, email address – processed during
                  employee recruitment.
                </li>
              </ul>
            </li>
            <li>
              The aforementioned periods of time are calculated from the end of
              the year in which the data processing began which ensures a swift
              and organized process of destroying personal data. Calculating the
              periods individually would result in technical and organizational
              difficulties, which are circumvented, resulting in a seamless
              process. Naturally, in case of exercising the right to be
              forgotten, every case is calculated and analyzed individually.
            </li>
            <li>
              The additional year included in the period of processing is
              dictated by the fact, that you are entitled to present a claim
              close to the end of limitation period as well as miscalculating
              the limitation period which may result in a necessity to process
              personal data longer, in order to address such claim.
            </li>
          </ol>
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Data subject’s rights</h2>
        <p>
          <ol>
            <li>
              According to GDPR, you have the right to:
              <ol className='a'>
                <li>Access your data and receive a copy;</li>
                <li>Correct your data;</li>
                <li>
                  Demand the removal of your data or object to its processing;
                </li>
                <li>Delete your data;</li>
                <li>Transfer your data;</li>
                <li>File a complaint with supervisory body.</li>
              </ol>
            </li>
            <li>
              The Company respects your rights derived from the GDPR and other
              legal acts regulating the protection of personal data and ensures
              maximized level of their realization.
            </li>
            <li>
              The Company hereby informs that all of the listed rights are not
              absolute, therefore in certain situations we can legally refuse to
              comply with them. Nonetheless, we assure you that any situation
              that requires refusal of exercising your rights is thoroughly
              analyzed and implemented only if unconditionally required by the
              law.
            </li>
            <li>
              In regard to your right to object processing of personal data The
              Company informs that at any moment you are entitled to object
              processing of personal data based on a legally justified reason by
              The Company (listed in section Basis and purposes of processing
              personal data). Nonetheless, The Company may refuse to cease the
              processing if it is able to prove that:
              <ul className='bullet'>
                <li>
                  there are valid legitimate grounds, superior to your interest
                  and rights that justify the continuation of processing your
                  data;
                </li>
                <li>
                  processing your data shall be necessary to determine, pursue
                  or defend our claims that may arise due to execution of the
                  agreement.
                </li>
              </ul>
            </li>
            <li>
              At any moment you are entitled to object the processing of your
              personal data for marketing purposes. In such case, we will
              immediately cease to process your personal data for this purpose.
            </li>
            <li>
              Your rights may be exercised by:
              <ol className='a'>
                <li>
                  Contacting The Company directly by sending an email to
                  legal@thegoodcode.io or,
                </li>
                <li>
                  Contacting The Company’s Data Protection Officer by sending an
                  email to aleksander.twardowski@adwokatura.pl .
                </li>
              </ol>
            </li>
            <li>
              If you reckon that your personal data is being processed with
              violation of law, you are entitled to submit a complaint regarding
              the processing of your personal data by The Company to the Polish
              President of Data Protection Office.
            </li>
          </ol>
        </p>
      </FadeInUpAnimation>
      <FadeInUpAnimation>
        <h2 className='my-10'>Final provisions</h2>
        <p>
          <ol>
            <li>
              Any matters not regulated herein shall be governed by the
              regulations of the Polish data protection laws and GDPR.
            </li>
            <li>
              You shall be informed of any changes applied to The Company’s
              privacy policy via email.
            </li>
            <li>Privacy policy shall apply from the date 21.05.2021</li>
          </ol>
        </p>
      </FadeInUpAnimation>
    </div>
  </ContentContainer>
)

export default PrivacyPolicy
